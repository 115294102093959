import React, { useRef } from 'react'
import './join.css'
import emailjs from '@emailjs/browser';

function Join() {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_r9pku4c', 'template_5u3u3xc', form.current, 'i34nW-bCJSerhYg_D')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
    <div className='Join' id='join_us'>
    <div className='left-j'>
    <hr/>
    <div>
    <span className='stroke-text'>READY TO </span> 
    <span>LEVEL UP</span> 
    </div>
    <div>
    <span>YOUR BODY </span> 
    <span className='stroke-text'>WITH US?</span> 
    </div>
    </div>
    <div className='right-j'>
    <form ref={form} className='email-container' onSubmit={sendEmail}>
        <input type="email" name="user_name" placeholder="Enter Your Email address " />
        <button type='submit~' className='btn btn-join'>Join Now</button>
    </form>
    </div>
    </div>
  )
}

export default Join
