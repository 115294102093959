import './App.css';
import Hero from './Components/Hero/Hero ';
import Program from './Components/Programs/program';
import Resons from './Components/Resons/resons';
import Plans from './Components/Plans/plans';
import Testimonial from './Components/Testimonial/testimonial';
import Join from './Components/Join/Join';
import Footer from './Components/Footer/Footer';

function App() {
  return (
    <div className='App'>
      <Hero/>
      <Program/>
      <Resons/>
      <Plans/>
      <Testimonial/>
      <Join/>
      <Footer/>
    </div>
  );
}

export default App;