import React from 'react'
import './footer.css'
import github from '../../assets/github.png'
import instagram from '../../assets/instagram.png'
import linkedin from '../../assets/linkedin.png'
import logo from '../../assets/logo.png'

function Footer() {
  return (
    <div className='Footer-container'>
          <hr/>
      <div className='footer'>
      <div className='social-links'>
        <img src={github} alt=''></img>
        <img src={instagram} alt=''></img>
        <img src={linkedin} alt=''></img>
      </div>
      <div className='logo-f'>
        <img src={logo} alt=''></img>
        </div>
    <div className='blur blur-f-1'></div>
    <div className='blur blur-f-2'></div>
      </div>
    </div>
  )
}

export default Footer
