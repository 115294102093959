import React from "react";
import "./plans.css";
import { plansData } from "../../data/plansData";
import whitetick from "../../assets/whiteTick.png";

function Plans() {
  return (
    <div className="plans-container" id="plans">
    <div className="blur blur-p-1"></div>
    <div className="blur blur-p-2"></div>
      <div className="programs-header" style={{ gap: "2rem" }}>
        <span className="stroke-text">Ready To Start </span>
        <span>Your Journey </span>
        <span className="stroke-text">Now With Us</span>
      </div>
      {/* Plans Cards */}
      <div className="plan">
        {plansData.map((plans, i) => (
          <div className="plans" key={i}>
            {plans.icon}
            <span>{plans.name}</span>
            <span>$ {plans.price}</span>
            <div className="features">
              {plans.features.map((feature, i) => (
                <div className="feature">
                  <img src={whitetick} alt=""></img>
                  <span key={i}>{feature}</span>
                </div>
              ))}
            </div>
            <div><span>See More Benefits -></span></div>
            <button className="btn">Join Now</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Plans;
