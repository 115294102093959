import React, { useState } from 'react'
import './testimonial.css'
import {testimonialData} from '../../data/testimonialData'
import leftarrow from '../../assets/leftArrow.png'
import rightarrow from '../../assets/rightArrow.png'
import {motion} from 'framer-motion'

function Testimonial() {
    const transaction = {type: 'spring', duration: 3,}
    const [selected, setselected] = useState(0);
    const tLength = testimonialData.length;
    return (
    <div className='testimonial-container' id='testimonial'>
    <div className='left-t'>
        <span>Testimonials</span>
        <span className='stroke-text'>What they</span>
        <span>say about us</span>
        <motion.span
        key={selected}
        initial={{opacity: '0', x: -100}}
        animate={{opacity: '1', x: 0}}
        exit={{opacity: '0', x: 100}}
        transition={transaction}
        >{testimonialData[selected].review}</motion.span>
        <span style={{color: "var(--orange"}}>{testimonialData[selected].name}{" "}
         - {testimonialData[selected].status}</span>
    </div>
    <div className='right-t'>
    <motion.div
            initial={{opacity:'0', x: -100}}
            transition={{...transaction, duration: 2}}
            whileInView={{opacity:'1', x:0}}
    ></motion.div>
    <motion.div
            initial={{opacity:'0', x: 100}}
            transition={{...transaction, duration: 2}}
            whileInView={{opacity:'1', x:0}}
    ></motion.div>
        <motion.img
        key={selected}
        initial={{opacity: '0', x: 100}}
        animate={{opacity: '1', x: 0}}
        exit={{opacity: '0', x: -100}}
        transition={transaction}
        src={testimonialData[selected].image} alt=''></motion.img>
        <div className='arrows'>
            <img
            onClick={()=> {
                selected===0?setselected(tLength -1): setselected((prev)=> prev -1)
            }} 
            src={leftarrow} alt=''></img>
            <img
            onClick={()=> {
                selected===tLength -1? setselected(0): setselected((prev) => prev +1)
            }} 
            src={rightarrow} alt=''></img>
        </div>
    </div>
    </div>
  )
}

export default Testimonial