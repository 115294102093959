import image1 from '../assets/t-image1.png';
import image2 from '../assets/t-image2.jpg';
import image3 from '../assets/t-image3.jpg';

export const testimonialData = [
    {
        image: image1,
        review: 'Lorem the industrys standard dummy text ever since the Ipsum has been 1500s, when an unknown printer took a galley of type and scrambled it to make',
        name: "Den Jose",
        status:"Team Manager"
    },
    {
        image: image2,
        review: 'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make',
        name: "King Kobra",
        status:"Project Manager"
    },
    {
        image: image3,
        review: 'The industrys standard dummy Lorem Ipsum has been text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make',
        name: "Lous Den",
        status:"Coach"
    },
]