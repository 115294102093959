import React from "react";
import "./Hero.css";
import Header from "../Header/Header";
import hero_image_back from '../../assets/hero_image_back.png';
import hero_image from '../../assets/hero_image.png';
import Heart from '../../assets/heart.png';
import Calories from '../../assets/calories.png'
import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'

function Hero() {
  const transaction = {type: 'spring', duration: 3,};
  const mobile = window.innerWidth<= 768 ? true: false;
  return (
    <div className="hero" id="hero">
    <div className="blur blur-h"></div>
      <div className="left-h">
        <Header />
        {/* The Best Add */}
        <div className="the-best-ad">
          <motion.div
            initial={{left: mobile ? '130px':'238px'}}
            whileInView={{left:'8px'}}
            transition={{...transaction, type:'tween'}}
            >
          </motion.div>
          <span> The best fitness club in the town</span>
        </div>

        {/* Hero Heading */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape </span>
            <span>Your</span>
          </div>
          <div>
            <span>Ideal body</span>
          </div>
          <div>
            <span>
              In here we will help you to shape and build your ideal body and
              live up your life to fullest
            </span>
          </div>
        </div>
        {/* Figures */}
        <div className="figures">
          <div>
            <span>
              <NumberCounter end={140} start={100} delay='4' preFix="+"></NumberCounter>
            </span>
            <span>expert coaches</span>
          </div>
          <div>
            <span><NumberCounter end={978} start={800} delay='4' preFix="+"></NumberCounter></span>
            <span>members joined</span>
          </div>
          <div>
            <span><NumberCounter end={50} start={0} delay='4' preFix="+"></NumberCounter></span>
            <span>fitness programs</span>
          </div>
        </div>
        {/* Hero Buttons */}
        <div className="hero-button">
          <button className="btn">Get Started</button>
          <button className="btn">Learn More</button>
        </div>
      </div>
      <div className="right-h">
        <button className="btn">Join Now</button>

        <motion.div
            initial={{right: '-2rem'}}
            whileInView={{right:'4rem'}}
            transition={transaction}
            className="heart-rate">
          <img src={Heart} alt="" />
          <span>Heart Rate</span>
          <span> 116 bpm</span>
        </motion.div>

        {/* Hero Images */}

        <img src={hero_image} alt="" className="hero-image" />
        <motion.img
        initial={{right: '11rem'}}
        whileInView={{right: '20rem'}}
        transition={transaction}
        src={hero_image_back} alt="" className="hero-image-back" />
        {/* Calories */}
        <motion.div
          initial={{right: '37rem'}}
          whileInView={{right:'28rem'}}
          transition={transaction}
         className="calories">
          <img src={Calories} alt="" className="" />
          <div>
          <span>Calories Burned</span>
            <span>220 kcal</span>
            </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Hero;
