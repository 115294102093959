import React, { useState } from "react";
import "./Header.css";
import Logo from "../../assets/logo.png";
import bars from "../../assets/bars.png";
import {Link} from 'react-scroll'

function Header() {
  const mobileBars = window.innerWidth <= 768 ? true : false;
  const [menuOpended, setMenuOpened] = useState(false);
  return (
    <div className="header">
      <img src={Logo} alt="" className="logo"></img>
      {menuOpended === false && mobileBars === true ? (
        <div style={{padding: '0.5rem 0 0 0'}}
        onClick={()=> setMenuOpened(true)}
        >
          <img src={bars} alt="" style={{width: '1.5rem', height: '1.5rem'}} />
        </div>
      ) : (
        <ul className="header-menu">
          <li><Link
          onClick={()=> setMenuOpened(false)}
          to="hero"
          spy={true}
          smooth={true}
          >Home</Link></li>
          <li><Link
          onClick={()=> setMenuOpened(false)}
          to="programs"
          spy={true}
          smooth={true}
          >Programs</Link></li>
          <li><Link
          onClick={()=> setMenuOpened(false)}
          to="resons"
          spy={true}
          smooth={true}
          >Why Us</Link></li>
          <li><Link
          onClick={()=> setMenuOpened(false)}
          to="plans"
          spy={true}
          smooth={true}
          >Plans</Link></li>
          <li><Link
          onClick={()=> setMenuOpened(false)}
          to="testimonial"
          spy={true}
          smooth={true}
          >Testimonials</Link></li>
        </ul>
      )}
    </div>
  );
}

export default Header;
